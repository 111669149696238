import styled from 'styled-components';
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Flex } from 'rebass/styled-components';
import { Cross as Hamburger } from 'hamburger-react';
import { menuItems } from '@/cms/header';
import { routes } from '@/routes';
import { styledTheme } from '@/styledTheme';
import { LogoSvg } from './LogoSvg';
import { HelloButton } from './Header/HelloButton';

export const HamburgerMenu = ({ white, isTransparent }: HamburgerMenuProps) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <MobileHeader isTransparent={isTransparent} white={white}>
      <HamburgerStyled isOpen={isOpen}>
        <Link to={routes.home}>
          <LogoSvg
            style={{
              top: 16,
              marginLeft: 24,
              color: isOpen || white ? 'white' : styledTheme.colors.primary,
              transition: '0.2s',
              // TODO use  backdrop filter for animation https://codepen.io/SitePoint/pen/xRpvzX
            }}
          />
        </Link>
        <Hamburger
          toggled={isOpen}
          toggle={setOpen}
          size={28}
          color={isOpen || white ? 'white' : styledTheme.colors.primary}
        />
      </HamburgerStyled>

      <MobileHeaderContents isOpen={isOpen}>
        {menuItems.map((menuItem) => (
          <MenuLink to={menuItem.url} key={menuItem.url} as={Link}>
            {menuItem.name}
          </MenuLink>
        ))}

        <HelloButton minHeight="69px" />
      </MobileHeaderContents>
    </MobileHeader>
  );
};

interface HamburgerMenuProps {
  white?: boolean;
  isTransparent?: boolean;
}

const defaultProps: HamburgerMenuProps = {
  white: false,
  isTransparent: false,
};

HamburgerMenu.defaultProps = defaultProps;

const MobileHeaderContents = styled(Flex)<{ isOpen: boolean }>`
  flex-direction: column;
  padding: 100px 0 0 90px;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.primary};
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? 0 : '100vw')};
  height: 100vh;
  width: 100vw;
  transition: 0.3s all ease-out;
  z-index: 999;
  overflow-y: scroll;

  > :last-child {
    margin-top: 60px;
  }
`;

const MenuLink = styled.div`
  color: white;
  text-decoration: none;
  font-style: normal;
  line-height: 40px;
  text-align: center;
  padding-bottom: 30px;
  font-weight: 300;
  font-size: 28px;
  letter-spacing: -0.01em;
  font-family: ${({ theme }) => theme.fonts.heading};
`;

const HamburgerStyled = styled(Flex)<{ isOpen: boolean }>`
  z-index: 99999;
  margin-top: 6px;
  margin-left: -24px;
  width: 100vw;
  justify-content: space-between;
  align-items: center;

  .hamburger-react {
    margin-top: 2px;
    margin-right: -10px;
    div {
      height: 1px !important;
    }
  }
`;

const MobileHeader = styled.div`
  z-index: 12345;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  background-color: ${({ isTransparent, white }) =>
    isTransparent || white ? 'transparent' : '#fff'};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: none;
  }
`;
