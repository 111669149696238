import * as React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface MetaWithProperty {
  property: string;
  content: string;
}

interface MetaWithName {
  name: string;
  content: string;
}

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: MetaWithProperty[] | MetaWithName[];
  customTitle?: string;
  img?: string;
}

function SEO({
  customTitle,
  description,
  meta,
  lang,
  img,
}: SEOProps): React.ReactElement {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const title = customTitle || site.siteMetadata.title;
  const image = img || `${site.siteMetadata.siteUrl}/skalar-share.png`;

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      // meta={[
      //   {
      //     name: 'description',
      //     content: metaDescription,
      //   },
      //   {
      //     property: 'og:title',
      //     content: title,
      //   },
      //   {
      //     property: 'og:description',
      //     content: metaDescription,
      //   },
      //   {
      //     property: 'og:type',
      //     content: 'website',
      //   },
      //   {
      //     name: 'twitter:card',
      //     content: 'summary',
      //   },
      //   {
      //     name: 'twitter:creator',
      //     content: site.siteMetadata.author,
      //   },
      //   {
      //     name: 'twitter:title',
      //     content: title,
      //   },
      //   {
      //     name: 'twitter:description',
      //     content: metaDescription,
      //   },
      //   { name: 'image', content: image },
      //   { property: 'og:image', content: image },
      //   { name: 'twitter:card', content: 'summary_large_image' },
      //   { name: 'twitter:image', content: image },
      // ].concat(meta)}
    >
      <html lang={lang} />
      <title>skalar studio</title>
      <meta name="title" content="skalar studio" />
      <meta
        name="description"
        content="skalar - data visualization design and development studio"
      />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.skalar.studio/" />
      <meta property="og:title" content="skalar studio" />
      <meta
        property="og:description"
        content="skalar - data visualization design and development studio"
      />
      <meta property="og:image" content={image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://www.skalar.studio/" />
      <meta property="twitter:title" content="skalar studio" />
      <meta
        property="twitter:description"
        content="skalar - data visualization design and development studio"
      />
      <meta property="twitter:image" content={image} />
      <meta name="viewport" content="width=device-width" />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  customTitle: null,
  img: null,
};

export default SEO;
