import { contact } from './contact';

const { email, phone, button, href } = contact;

export const cta = {
  text: 'Let’s make something beautiful together',
  contactText: `Want to talk about a project?
Contact us!`,
  phone,
  email,
  helloButton: {
    text: button,
    href,
  },
};
