import { css } from 'styled-components';

export const underlineLinks = (
  color: string | (({ theme }: { theme: any }) => any) = 'currentColor',
) => css`
  a {
    position: relative;
    padding: 3px 0 8px;
    background-image: linear-gradient(${color}, ${color}),
      linear-gradient(transparent, transparent);
    background-size: 0 1px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all 0.2s;

    &.footer-link {
      padding: 3px 0 4px;
    }
  }

  a:not(.active):hover {
    background-size: 100% 1px, auto;
  }
`;
