import * as React from 'react';
import { Link as RouterLink } from 'gatsby';
import styled from 'styled-components';
import { Flex, Box, Text, Link } from 'rebass/styled-components';
import { routes } from '@/routes';
import { footer } from '@/cms/footer';
import { contact } from '@/cms/contact';
import { styledTheme } from '@/styledTheme';
import { underlineLinks } from '@/utils/underlineLinks';
import { ContentWrapper } from './ContentWrapper';
import { LogoSvg } from './LogoSvg';
import { SocialTMP } from './SocialTMP';

export const Footer: React.FC = () => (
  <Wrapper as="footer" py={4}>
    <ContentWrapper maxWidth={1000}>
      <Flex flexWrap="wrap" className="footer-content">
        <Box width={[1, 1, 1 / 5]}>
          <RouterLink to={routes.home}>
            <LogoSvg />
          </RouterLink>
        </Box>

        <Flex
          justifyContent="flex-start"
          width={[1 / 2, 1 / 2, 1 / 3, 2 / 5]}
          flexWrap="wrap"
          css={underlineLinks()}
        >
          {footer.links.map((l) => (
            <Flex width={[1, 1, 1 / 2]} mb={1}>
              <Link
                to={l.hash ? l.url + l.hash : l.url}
                key={l.name}
                as={RouterLink}
                className="footer-link"
              >
                <Text fontSize="16px">{l.name}</Text>
              </Link>
            </Flex>
          ))}
        </Flex>

        <Flex
          flexDirection="column"
          width={[1, 1, '25%']}
          mt={[4, 4, 0]}
          css={underlineLinks()}
        >
          <Flex>
            <Link href={`mailto:${contact.email}`} mb="6px">
              {contact.email}
            </Link>
          </Flex>
          {/* <SocialTMP */}
          {/*  color={styledTheme.colors.secondary} */}
          {/*  style={{ transform: 'scale(0.8)', transformOrigin: 'top left' }} */}
          {/* /> */}
        </Flex>

        <Flex
          justifyContent="flex-end"
          width={[1 / 2, 1 / 2, 1 / 5, '15%']}
          mt={[3, 3, 0]}
          color={styledTheme.colors.secondary}
        >
          © skalar 2021
        </Flex>
      </Flex>
    </ContentWrapper>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.heading};

  a {
    font-family: ${({ theme }) => theme.fonts.heading};
    color: ${({ theme }) => theme.colors.primary};
  }

  g:hover {
    cursor: pointer;
    pointer-events: bounding-box;
    > path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  .footer-content {
    > :nth-child(1) {
      order: 1;
    }

    > :nth-child(3) {
      order: 2;
      margin-bottom: 15px;
    }

    > :nth-child(2) {
      order: 3;
    }

    > :nth-child(4) {
      order: 4;
      justify-content: flex-end !important;
      text-align: end;
      align-items: flex-end;
      padding-bottom: 16px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    .footer-content {
      > :nth-child(1) {
        order: 1;
      }

      > :nth-child(3) {
        order: 3;
        margin-bottom: 0;
      }

      > :nth-child(2) {
        order: 2;
      }

      > :nth-child(4) {
        order: 4;
        justify-content: flex-start;
        text-align: end;
        align-items: flex-start;
        padding-bottom: 0;
      }
    }
  }
`;
