import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';
import { styledTheme } from '@/styledTheme';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Header } from './Header/Header';
import { Footer } from './Footer';
import SEO from './SEO';
import './reset.css';
import './fonts.css';
import { GlobalStyle } from './GlobalStyle';

export const Layout: React.FC<LayoutProps> = ({
  children,
  white,
  isTransparent,
}: LayoutProps) => {
  gsap.registerPlugin(ScrollTrigger);

  return (
    <ThemeProvider theme={styledTheme}>
      <GlobalStyle />
      <SEO />
      <Flex
        flexDirection="column"
        minHeight="100vh"
        justifyContent="space-between"
      >
        <Header white={white} isTransparent={isTransparent} />
        <Box
          as="main"
          minHeight="calc(100vh - 80px - 234px)"
          mt={['60px', '60px', '80px']}
        >
          {children}
        </Box>
        <Footer />
      </Flex>
    </ThemeProvider>
  );
};

interface LayoutProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  white?: boolean;
  isTransparent?: boolean;
}

const defaultProps: LayoutProps = {
  white: false,
  isTransparent: false,
};

Layout.defaultProps = defaultProps;

export default Layout;
