import { Link as RouterLink } from 'gatsby';
import * as React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex, Link } from 'rebass/styled-components';
import { routes } from '@/routes';
import { menuItems } from '@/cms/header';
import { useStickyHeaderStyles } from '@/hooks/useStickyScroll';
import { underlineLinks } from '@/utils/underlineLinks';
import { styledTheme } from '@/styledTheme';
import { ContentWrapper } from '../ContentWrapper';
import { HamburgerMenu } from '../HamburgerMenu';
import { LogoSvg } from '../LogoSvg';

export const Header = ({ white, isTransparent }: HeaderProps) => {
  const [activeUrl, setActiveUrl] = useState(null);
  const stickyHeaderStyles = useStickyHeaderStyles();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setActiveUrl(window.location.pathname);
    }
  });

  return (
    <StyledHeader
      width="100vw"
      height={['60px', '60px', '80px']}
      style={stickyHeaderStyles}
      isTransparent={isTransparent}
      white={white}
    >
      <StyledMenu maxWidth={1000}>
        <StyledLink
          to={routes.home}
          style={{ position: 'absolute' }}
          as={RouterLink}
          ml={['20px', '20px', '0px']}
        >
          <LogoSvg
            style={{
              color: white && stickyHeaderStyles.headerPos < 550 && '#fff',
            }}
          />
        </StyledLink>

        <DesktopMenu whiteUnderline={white}>
          {menuItems.map((menuItem) => (
            <>
              {menuItem.isDesktop && (
                <Link
                  to={menuItem.url}
                  key={menuItem.url}
                  className={menuItem.url === activeUrl ? 'active' : ''}
                  as={RouterLink}
                  fontFamily={styledTheme.fonts.heading}
                  style={{
                    color:
                      white && stickyHeaderStyles.headerPos < 550 && '#fff',
                  }}
                >
                  {menuItem.name}
                </Link>
              )}
            </>
          ))}
        </DesktopMenu>

        <HamburgerMenu
          white={white && stickyHeaderStyles.headerPos < 550 && true}
          isTransparent={isTransparent}
        />
      </StyledMenu>
    </StyledHeader>
  );
};

interface HeaderProps {
  white?: boolean;
  isTransparent?: boolean;
}

const defaultProps: HeaderProps = {
  white: false,
  isTransparent: false,
};

Header.defaultProps = defaultProps;

const StyledHeader = styled(Flex)`
  z-index: 99999;
  max-width: 100vw;
  display: flex;
  background-color: ${({ isTransparent }) =>
    isTransparent === true ? 'transparent' : '#fff'};
`;

const StyledMenu = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledLink = styled(Link)`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: block;
  }
`;

const DesktopMenu = styled.div`
  * {
    z-index: 100000;
    font-size: 1em;
  }

  ${underlineLinks(({ theme, whiteUnderline }) =>
    whiteUnderline ? theme.colors.white : theme.colors.primary,
  )}

  a.active {
    ::after {
      content: '';
      position: absolute;
      bottom: 0px;
      background-color: ${({ theme }) => theme.colors.primary};
      transition: opacity 300ms, transform 200ms;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      left: calc(50% - 2px);
    }
  }

  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    > * {
      margin-left: 32px;
      color: ${({ theme }) => theme.colors.textWhite};
    }
  }

  @media screen and (min-width: 1200px) {
    > * {
      margin-left: 64px;
    }
  }
`;

export default Header;
