import * as React from 'react';
import { Link } from 'gatsby';
import { Button } from 'rebass/styled-components';
import { cta } from '@/cms/cta';

export const HelloButton = (props) => (
  <Button variant="outline" as={Link} href={cta.helloButton.href} {...props}>
    {cta.helloButton.text}
  </Button>
);
