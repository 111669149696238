import { routes } from '../routes';

export const menuItems = [
  {
    name: 'home',
    url: routes.home,
    isDesktop: false,
  },
  {
    name: 'about',
    url: routes.about,
    isDesktop: true,
  },
  {
    name: 'work',
    url: routes.work,
    isDesktop: true,
  },
  {
    name: 'contact',
    url: routes.contact,
    isDesktop: true,
  },
  {
    name: 'jobs',
    url: routes.jobs,
    isDesktop: true,
  },
];
