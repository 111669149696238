import { useEffect, useState } from 'react';

export function debounce(func: () => void, wait: number, immediate = false) {
  let timeout: number | null;
  return function () {
    const later = function () {
      timeout = null;
      // eslint-disable-next-line prefer-rest-params
      if (!immediate) func.apply(this, arguments);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    // eslint-disable-next-line prefer-rest-params
    if (callNow) func.apply(this, arguments);
  };
}

export const useStickyHeaderStyles = (headerHeight = 60) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [headerPos, setHeaderPos] = useState(0);

  const handleScroll = debounce(
    () => {
      const currentScrollPos = window.pageYOffset;
      setHeaderPos(currentScrollPos);
      const direction = prevScrollPos - currentScrollPos < 0 ? 'down' : 'up';

      setVisible(direction === 'up' || currentScrollPos < headerHeight);

      setPrevScrollPos(currentScrollPos);
    },
    100,
    true,
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return {
    position: 'fixed',
    transition: visible ? 'top 0.2s' : 'top 0.3s',
    top: visible ? '0' : -2 * headerHeight,
    headerPos,
  };
};
