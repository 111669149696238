import React from 'react';

const email = 'hello@skalar.studio';

export const contact = {
  email,
  href: `mailto:${email}`,
  phone: '+48 668 317 669',
  button: 'Say hello!',
  texts: ['Want to talk about a project?', 'Contact us!'],
};
