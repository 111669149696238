import * as React from 'react';
import { styledTheme } from '@/styledTheme';

interface ILogoSVG {
  style?: React.CSSProperties;
}

export const LogoSvg: React.FC<ILogoSVG> = (props) => (
  <svg
    width="73"
    height="18"
    viewBox="0 0 73 18"
    color={styledTheme.colors.primary}
    {...props}
  >
    <path
      d="M0 14.1767L2.64336 13.5788C2.91084 14.869 3.79196 15.4827 5.0979 15.4827C6.43531 15.4827 7.14335 14.9792 7.14335 14.161C7.14335 11.6593 0.440559 13.7519 0.440559 8.73268C0.440559 6.64003 2.18706 5.00366 4.98776 5.00366C7.36363 5.00366 9.23601 6.19947 9.67657 8.418L7.22203 9.12604C6.98601 8.04038 6.18356 7.55261 5.00349 7.55261C3.82343 7.55261 3.11538 8.07184 3.11538 8.79562C3.11538 11.1086 10.007 8.93723 10.007 13.9093C10.007 16.3323 8.07168 17.9844 5.03496 17.9844C2.32867 18.0002 0.330419 16.71 0 14.1767Z"
      fill="currentColor"
    />
    <path
      d="M12.8391 0H15.6241V9.73951H17.0874L20.1083 5.33392H23.4125L19.5104 10.8252V11.0455L23.6328 17.701H20.3129L17.1346 12.3042H15.6241V17.701H12.8391V0Z"
      fill="currentColor"
    />
    <path
      d="M24.5927 11.5174C24.5927 7.77267 27.0472 5.03491 30.3514 5.03491C32.4283 5.03491 33.5612 6.10484 34.0962 6.84435H34.3164V5.33386H37.1014V14.7902C37.1014 15.1206 37.2902 15.3409 37.6678 15.3409H38.2185V17.701H36.1259C35.0245 17.701 34.458 16.9458 34.3636 16.1433H34.1591C33.6871 16.8985 32.6014 17.9999 30.4615 17.9999C27.0944 17.9999 24.5927 15.2622 24.5927 11.5174ZM34.3636 11.5332C34.3636 9.07862 32.7902 7.694 30.9021 7.694C28.9196 7.694 27.4406 9.2517 27.4406 11.5332C27.4406 13.8618 28.9825 15.3094 30.9336 15.3094C33.0105 15.3094 34.3636 13.6416 34.3636 11.5332Z"
      fill="currentColor"
    />
    <path
      d="M40.2168 15.2465H42.5297V2.47028H40.2168V0H45.3147V15.2465H47.6276V17.701H40.2168V15.2465Z"
      fill="currentColor"
    />
    <path
      d="M49.5157 11.5174C49.5157 7.77267 51.9703 5.03491 55.2745 5.03491C57.3514 5.03491 58.4843 6.10484 59.0192 6.84435H59.2238V5.33386H62.0088V14.7902C62.0088 15.1206 62.1976 15.3409 62.5752 15.3409H63.1259V17.701H61.0332C59.9318 17.701 59.3654 16.9458 59.271 16.1433H59.0507C58.5787 16.8985 57.493 17.9999 55.3532 17.9999C52.0175 17.9999 49.5157 15.2622 49.5157 11.5174ZM59.2867 11.5332C59.2867 9.07862 57.7133 7.694 55.8252 7.694C53.8427 7.694 52.3636 9.2517 52.3636 11.5332C52.3636 13.8618 53.8899 15.3094 55.8567 15.3094C57.9336 15.3094 59.2867 13.6416 59.2867 11.5332Z"
      fill="currentColor"
    />
    <path
      d="M65.722 5.33373H68.4755V7.14317H68.6643C69.1364 6.10471 70.3322 5.01904 71.9213 5.01904H72.8339V7.80401H71.6066C69.5769 7.80401 68.4913 9.23583 68.4913 11.5802V17.6851H65.7063V5.33373H65.722Z"
      fill="currentColor"
    />
  </svg>
);

LogoSvg.defaultProps = {
  style: {},
};
