import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.colors.primary};
    overflow-x: hidden;
    position: relative;
  }

  hr {
    background: ${({ theme }) => theme.colors.divider};
  }

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
